<template>
    <div>
        <div v-if="fetchingCampaignData">
            <div style="min-height: 70vh; display: flex; justify-content: center; align-items: center;">
                <i class="fas fa-spinner fa-spin" style="font-size: 40px;"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fetchingCampaignData: false,
            token: '',
        }
    },

    mounted() {
        this.fetchingCampaignData = true;
        this.token = this.$route.query.token;
        
        if(this.token) {
            this.ssoValidationToken();
        } else {
            this.fetchingCampaignData = false
            window.document.location = '/login';
        }
    },

    methods: {
        ssoValidationToken() {
            this.$store.dispatch('ssoValidationToken', {
                subdomain: window.location.hostname,
                token: this.token
            })
            .then(response => {
                // console.log(response);

                this.fetchingCampaignData = false;
                
                if(response.status == 200 && response.data.message == "Access Token Valid") {
                    this.getUserData();
                }
            })
            .catch(error => {
                console.error(error);

                this.fetchingCampaignData = false;
                window.document.location = "/login";
                

                // swal.fire({
                //     title: 'Error',
                //     text: error.response.data.message,
                //     showConfirmButton: true,
                //     icon: 'error'
                // });
                
            })
        },

        getUserData() {
            this.$store.dispatch('retrieveUserData',{})
                .then(response => {
                    // console.log(response);
                    
                    if(response == 'success') {
                        const userData = this.$store.getters.userData;
                        
                        /** SET TO STORAGE FOR SIDEMENU */
                            // userData.leadlocalname = this.$global.globalModulNameLink.local.name;
                            // userData.leadlocalurl = this.$global.globalModulNameLink.local.url;

                            // userData.leadlocatorname = this.$global.globalModulNameLink.locator.name;
                            // userData.leadlocatorurl = this.$global.globalModulNameLink.locator.url;
                            
                            this.$global.globalModulNameLink.local.name = userData.leadlocalname;
                            this.$global.globalModulNameLink.local.url = userData.leadlocalurl;

                            this.$global.globalModulNameLink.locator.name = userData.leadlocatorname;
                            this.$global.globalModulNameLink.locator.url = userData.leadlocatorurl;

                            this.$global.globalModulNameLink.enhance.name = userData.leadenhancename;
                            this.$global.globalModulNameLink.enhance.url = userData.leadenhanceurl;

                            userData.idsys = this.$global.idsys;

                            const updatedData = {
                                leadlocalname: this.$global.globalModulNameLink.local.name,
                                leadlocalurl: this.$global.globalModulNameLink.local.url,
                                leadlocatorname: this.$global.globalModulNameLink.locator.name,
                                leadlocatorurl: this.$global.globalModulNameLink.locator.url,
                                idsys: this.$global.idsys
                            }

                            this.$store.dispatch('updateUserData', updatedData);
                        /** SET TO STORAGE FOR SIDEMENU */
                        
                        const usetupcompleted = this.$store.getters.userData.profile_setup_completed
                        if (usetupcompleted == "F") {
                            window.document.location = "/user/profile-setup";
                        }else{
                            //const userData = this.$store.getters.userData;
                            if (userData.menuLeadspeek == true && userData.user_type == 'client' && userData.leadspeek_type == 'locator') {
                                window.document.location = "/" + this.$global.globalModulNameLink.locator.url + '/dashboard';
                            }else if (userData.menuLeadspeek == true && userData.user_type == 'client' && userData.leadspeek_type == 'local') {
                                window.document.location = "/" + this.$global.globalModulNameLink.local.url + '/dashboard';
                            }else if (((userData.user_type == 'sales' && userData.status_acc != 'completed') || (userData.user_type == 'sales' && userData.status_acc == 'completed' && userData.isAccExecutive == 'F') ) && userData.systemuser) {
                                window.document.location = "/configuration/sales-connect-account";
                            }else if ((userData.user_type == 'user' || userData.user_type == 'userdownline' || userData.user_type == 'sales') && userData.systemuser) {
                                window.document.location = "/configuration/agency-list";
                            }else if ((userData.user_type == 'user' || userData.user_type == 'userdownline') && (userData.charges_enabled == false || userData.payouts_enabled == false)) {
                                window.document.location = "/configuration/general-setting";
                            }else if (userData.user_type == 'user') {
                                window.document.location = "/" + this.$global.globalModulNameLink.local.url + '/dashboard';
                            }else{
                                //window.document.location = "/user/profile-setup";
                                window.document.location = "/" + this.$global.globalModulNameLink.local.url + '/dashboard';
                            }
                        }
                    }else{
                        swal.fire({
                            icon: 'error',
                            title: 'Sorry!',
                            text: 'There is currently an update processing on the platform. Please wait five minutes and try again.',
                        })
                    }
                })

        },
    },
}
</script>